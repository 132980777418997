import { put, call, takeLeading, select } from 'redux-saga/effects';
import Bugsnag from '@bugsnag/js';

import Notification from '../../../../../components/layout/Notification';
import { locationsDistanceInMiles } from '../../../../../util/GetLocationsDistance';
import * as actions from '../../../reducers/ducks/legacy/RestaurantsDuck';
import { setSelectedRestaurantMenu } from '../../../reducers/ducks/new/Restaurant/RestaurantReducer';
import {
  getRestaurants,
  getAllRestaurants,
  getAllDeals,
} from '../../../../../util/api/ConsumerApiClient';
import {
  getRestaurantMenu as apiGetRestaurantMenu,
  getRestaurantDetails,
} from '../../../../../util/api/RestaurantApiClient';
import {
  getTimeParams,
  getFormattedRestaurantMenu,
} from '../../../../../util/Restaurant';

export const emptyAddressString = 'empty address string';
export const invalidAddress = 'Invalid address';
export const AddressNotCovered = 'Address is not covered';

export const filterRestaurantsResponse = (
  response,
  address,
  distance,
  type
) => {
  const result = {
    restaurants: {},
    promos: [],
    favourites: [],
    delays: {
      delivery: null,
      takeout: null,
    },
  };

  const getDistanceFromAddress = (lat, lng, addressData) =>
    locationsDistanceInMiles(addressData, { latitude: lat, longitude: lng });

  response.forEach((restaurant) => {
    let distanceFromAddress;
    if (address) {
      distanceFromAddress = getDistanceFromAddress(
        restaurant.location.latitude,
        restaurant.location.longitude,
        address
      );
    }

    if (type === 'delivery' || (!!distance && distance < distanceFromAddress))
      return;

    result.restaurants[restaurant.profile] = {
      ...restaurant,
      distance: distanceFromAddress,
    };

    if (
      result.delays.delivery === null ||
      result.delays.delivery > restaurant.delays.delivery
    ) {
      result.delays.delivery = restaurant.delays.delivery;
    }
    if (
      result.delays.takeout === null ||
      result.delays.takeout > restaurant.delays.takeout
    ) {
      result.delays.takeout = restaurant.delays.takeout;
    }

    const isFeatured = restaurant.features.includes('featured');
    if (isFeatured) {
      result.favourites.push(restaurant.profile);
    }
    result.promos.push(restaurant.profile);
  });

  return result;
};

export function* allRestaurants({ payload }) {
  try {
    const { type, address, distance } = yield select(
      ({ order, time: selectedTime }) => ({
        type: order.type,
        time: selectedTime.selectedTime,
        distance: order.distance,
        address: order.address,
      })
    );

    const requestParams = {
      mode: type.value,
    };

    const response = yield call(getAllRestaurants, requestParams);
    if (!response) throw new Error('No restaurants found');

    const filteredRestaurants = filterRestaurantsResponse(
      response,
      address,
      distance,
      type
    );

    yield put(actions.setFavourites(filteredRestaurants.favourites));
    yield put(actions.setPromos(filteredRestaurants.promos));
    yield put(actions.setDelays(filteredRestaurants.delays));
    yield put(actions.setRestaurants(filteredRestaurants.restaurants));

    if (typeof payload === 'function') {
      payload(response);
    }

    if (payload?.navigateTo) {
      payload.navigateTo();
    }
  } catch (err) {
    yield put(actions.setRestaurants({}));
    yield put(actions.setFavourites([]));
    yield put(actions.setPromos([]));

    Bugsnag.notify(err);

    Notification.error(
      err?.data?.message || err?.message || 'Error when requesting restaurants',
      {
        toastId: 'ErrorWhenRequestingRestaurants',
      }
    );

    if (!payload?.failCallback) return;

    const resp = payload.failCallback();
    if (resp?.redirect && resp?.historyPush) {
      yield call(resp.historyPush, resp.redirect);
    }
  }
}

export function* searchRestaurants({ payload }) {
  try {
    const { type, time, address, distance } = yield select(
      ({ order, time: selectedTime, address: selectedAddress }) => ({
        type: order.type,
        time: selectedTime.selectedTime,
        distance: order.distance,
        address: selectedAddress.selectedAddress,
      })
    );

    const params = {
      ...getTimeParams(time, false, payload?.asap || false),
      mode: type.value,
    };
    if (address) {
      params.lat = address.latitude;
      params.lng = address.longitude;
    }

    const response = yield call(getRestaurants, params);

    const filteredRestaurants = filterRestaurantsResponse(
      response,
      address,
      distance,
      type
    );

    yield put(actions.setFavourites(filteredRestaurants.favourites));
    yield put(actions.setPromos(filteredRestaurants.promos));
    yield put(actions.setDelays(filteredRestaurants.delays));
    yield put(actions.setRestaurants(filteredRestaurants.restaurants));

    if (typeof payload === 'function') {
      payload(response);
    }

    if (payload?.navigateTo) {
      payload.navigateTo();
    }
  } catch (err) {
    yield put(actions.setRestaurants({}));
    yield put(actions.setFavourites([]));
    yield put(actions.setPromos([]));

    Bugsnag.notify(err);

    Notification.error(
      err?.data?.message || err?.message || 'Error when requesting restaurants',
      {
        toastId: 'ErrorWhenRequestingRestaurants',
      }
    );

    if (!payload?.failCallback) return;

    const resp = payload.failCallback();
    if (resp?.redirect && resp?.historyPush) {
      yield call(resp.historyPush, resp.redirect);
    }
  }
}

export function* getRestaurantDetailsSaga({ payload }) {
  try {
    const response = yield call(getRestaurantDetails, payload);

    yield put(
      actions.getRestaurantDetailsResponse({
        [payload]: { response },
      })
    );
  } catch (error) {
    yield put(
      actions.getRestaurantDetailsResponse({
        [payload]: { error },
      })
    );
  }
}

export function* getRestaurantMenuSaga({ payload }) {
  try {
    const { type, time, address, selectedRestaurant } = yield select(
      ({
        order,
        time: selectedTime,
        address: selectedAddress,
        restaurant,
      }) => ({
        type: order.type,
        time: selectedTime.selectedTime,
        address: selectedAddress.selectedAddress,
        selectedRestaurant: restaurant.selectedRestaurantId,
      })
    );

    const params = {
      ...(payload.asapDate
        ? {
            start: payload.asapDate.value.start,
            end: payload.asapDate.value.end,
          }
        : getTimeParams(time, true)),
      mode: type.value,
      client: selectedRestaurant,
    };
    if (address) {
      params.lat = address.latitude;
      params.lng = address.longitude;
    }

    let requestType;

    if (time.type === 'asap') {
      requestType = payload.asapDate ? 'target' : 'asap';
    } else {
      requestType = 'target';
    }

    const response = yield call(apiGetRestaurantMenu, requestType, params);

    const { categories, menuItems, dynamicFeesValues } =
      getFormattedRestaurantMenu(response);

    yield put(
      actions.getRestaurantMenuResponse({
        response: {
          categories,
          menuItems,
          dynamicFeesValues,
          fees: response.fees,
          delays: response.delays,
          minimums: response.minimums,
        },
      })
    );

    yield put(
      setSelectedRestaurantMenu({
        categories,
        menuItems,
        dynamicFeesValues,
        fees: response.fees,
        delays: response.delays,
        minimums: response.minimums,
      })
    );
  } catch (err) {
    Bugsnag.notify(err);

    // This should be replaced with better error messages
    Notification.error(
      err?.data?.message || err?.message || 'Error when requesting restaurants',
      {
        toastId: 'ErrorWhenRequestingRestaurants',
      }
    );

    yield put(
      actions.getRestaurantMenuResponse({
        error:
          err?.data?.message ||
          err?.message ||
          'Error when requesting restaurant menu',
      })
    );
  }
}

export function* searchDealsSaga() {
  try {
    const response = yield call(getAllDeals, {});
    yield put(actions.setDeals(response));
  } catch (err) {
    yield put(actions.setDeals({}));
  }
}

export function* watchRestaurantsSagas() {
  yield takeLeading(actions.searchRestaurants.type, searchRestaurants);
  yield takeLeading(actions.allRestaurants.type, allRestaurants);
  yield takeLeading(
    actions.getRestaurantDetailsRequest.type,
    getRestaurantDetailsSaga
  );
  yield takeLeading(
    actions.getRestaurantMenuRequest.type,
    getRestaurantMenuSaga
  );
  yield takeLeading(actions.searchDeals.type, searchDealsSaga);
}
